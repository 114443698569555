<template>
    <v-row no-gutters>
        <template v-for="(item) in editedItem" >
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="!item.hide" :key="item.name">
                <v-autocomplete v-if="item.options"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event, item)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                />
                <v-switch v-if="!item.options && item.type === 'INT' && item.columnType === 'check'"
                    class="my-0"
                    :label="item.label"
                    v-model="item.value"
                />
                <v-text-field v-if="item.name === 'description'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <v-text-field v-else-if="item.name === 'country'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <v-text-field v-else-if="item.name === 'zip_code'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="zipCodeNotFound"
                    :hint="zipCodeNotFound ? hintMessage : ''"
                    persistent-hint
                    :counter="item.length"
                    :maxlength="item.length"
                    @focus="backupZipCode = item.value, zipCodeNotFound = false"
                    @blur="setAddress(item.value)"
                />

                <!-- readonly tabindex="-1" -->
                <v-text-field v-else-if="item.name === 'state'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    :loading="fetchingAddress"
                    :disabled="disableAddressInput"
                />

                <v-text-field v-else-if="item.name === 'city'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    :loading="fetchingAddress"
                    :disabled="disableAddressInput"
                />

                <v-text-field v-else-if="item.name === 'district'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    :loading="fetchingAddress"
                    :disabled="disableAddressInput"
                />

                <v-text-field v-else-if="item.name === 'street'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    :loading="fetchingAddress"
                    :disabled="disableAddressInput"
                />

                <v-text-field v-else-if="item.name === 'address_number'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <v-text-field v-else-if="item.name === 'complement'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <v-text-field v-else-if="item.name === 'contact_name'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <v-text-field v-else-if="item.name === 'contact_phone'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <v-text-field v-else-if="item.name === 'contact_email'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <!-- <v-autocomplete v-if="item.options && item.name === 'address_type' && !show"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                />

                <v-text-field v-if="item.columnId === 'id_address_type' && show"
                    v-model="item.value"
                    label="Tipo do endereço *"
                    type="text"
                />

                <v-btn v-if="item.columnId === 'id_address_type'" @click="show = !show, item.value = null, $root.$emit('saveTypeAddress', show)" class="primary--text pa-0 mt-n4" text>
                    <span v-if='!show'>
                        + Criar novo tipo de Endereço
                    </span>
                    <span v-if='show'>
                        Escolher um tipo já salvo
                    </span>
                </v-btn> -->
            </v-col>
        </template>
    </v-row>
</template>

<script>
// import Vue from 'vue'
import lodash from 'lodash'

export default {
    name: 'ZnapDialog',

    props: {
        editedItem: { type: Array, required: true },
        editedIndex: { type: Number, required: true },
        disabled: { type: Boolean, required: true },
        options: { type: Array, required: true },
        vendorAddress: { type: Boolean, default: false },
    },

    data() {
        return {
            datepicker: [],
            date: '',
            selected: [],

            zipCodeNotFound: false,
            fetchingAddress: false,
            hintMessage: null,
            backupZipCode: null,
            show: false,

            filterConditions: [],

            // options: [
            //     {
            //         column: 'id_address_type',
            //         is: 'ComboBox',
            //         items: [],
            //         multiple: false,
            //         label: 'Tipo de endereço',
            //         required: false,
            //         filterable: false,
            //         endpoint: [ Vue.prototype.$ipCustomer, 'address-type' ]
            //     },
            // ],
        }
    },

    computed: {
        disableAddressInput() {
            let disabled = false
            let zipCodeItem = this.editedItem.find(item => item.name === 'zip_code')
            let countryItem = this.editedItem.find(item => item.name === 'country')

            if (countryItem.value === 'Brasil') {
                if (zipCodeItem.value === null || zipCodeItem.value === '') {
                    disabled = true
                }
            }

            return disabled
        }
    },

    methods: {
        addressInitialState() {
            this.editedItem.forEach(item => {
                if (item.name === 'state') {
                    item.value = null
                } else if (item.name === 'city') {
                    item.value = null
                } else if (item.name === 'district') {
                    item.value = null
                } else if (item.name === 'street') {
                    item.value = null
                } else if (item.name === 'complement') {
                    item.value = null
                }
            })
        },

        async setAddress(zipCode) {
            let countryItem = this.editedItem.find(item => item.name === 'country')

            if (countryItem.value === 'Brasil') {
                if (this.backupZipCode !== zipCode) {
                    this.zipCodeNotFound = false
    
                    if (zipCode === null || zipCode === '') {
                        this.hintMessage = 'Digite o CEP do seu endereço'
                        this.zipCodeNotFound = true
    
                        this.addressInitialState()
                    } else {
                        let payload = {
                            cep: this.$options.filters.zipCodeFilter(zipCode)
                        }
    
                        if (payload.cep.length === 9) {
                            this.fetchingAddress = true
    
                            try {
                                let res = await this.$http.post(this.$ipCustomer + 'address/list-cep', { ...payload })
                                if (res) {
                                    let address = res.data.id
            
                                    if (lodash.isEmpty(address)) {
                                        this.hintMessage = 'CEP inválido'
                                        this.zipCodeNotFound = true
            
                                        this.addressInitialState()
                                    } else {
                                        this.editedItem.forEach(item => {
                                            if (item.name === 'state') {
                                                item.value = address.state
                                            } else if (item.name === 'city') {
                                                item.value = address.city
                                            } else if (item.name === 'district') {
                                                item.value = address.district
                                            } else if (item.name === 'street') {
                                                item.value = address.street
                                            } else if (item.name === 'complement') {
                                                item.value = address.complement
                                            }
                                        })
                                    }
            
                                    this.fetchingAddress = false
                                }
                            } catch (err) {
                                this.fetchingAddress = false
                                this.$fnError(err)
                            }
                        } else {
                            this.hintMessage = 'CEP inválido'
                            this.zipCodeNotFound = true
    
                            this.addressInitialState()
                        }
                    }
                }
            }
        },

        getOptions(item) {
            let option = this.options.find(option => option.column === item.columnId)
            return option.items
        },
        
        setSelectedOption(columnId, e) {
            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === columnId) this.editedItem[i].value = e.id
                } else {
                    if (item.name === columnId) this.editedItem[i].value = null
                }
            })
        },

        requiredValidation(item) {
            if (item && item.value !== null) {
                if ( item.value.length === 0 ) return true
            }
        },

        isRequired(item) {
            return item.rules.includes('required') ? true : false 
        },

        formatSwitchValue(item) {
            let value = null

            if (item.value === 1) {
                value = true
                item.value = 1
            }

            if (!item.value) {
                value = false
                item.value = 0
            }

            return value
        },

        setSwitchValue(e, item) {
            if (e) {
                item.value = 1
            } else {
                item.value = 0
            }
        }
    },

    created() {
        this.options.forEach((option, i) => {
            let populate = true

            this.filterConditions.forEach(condition => {
                if (condition.column === option.column) {
                    populate = false
                }
            })

            let payload = {
                filter: {
                    conditions: []
                }
            }

            if (option.filterable) {
                this.filterConditions.forEach(condition => {
                    if (condition.required) {
                        payload.filter.conditions.push(condition)
                    }
                })
            }

            if (!option.required && populate) {
                option.items = []

                if (option.conditions) {
                    payload.filter.conditions.push(...option.conditions)
                }

                this.$http.post(option.endpoint[0] + option.endpoint[1] + '/list-options', { ...payload } )
                    .then((res) => {
                        res.data.rows.forEach(row => {
                            return this.options[i].items.push({ id: row.id, text: row.text })
                        })
                    }).catch((err) => {
                        this.$fnError(err)
                    })
            }    
        })
    },
}
</script>

<style>

</style>