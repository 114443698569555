<template>
    <v-row no-gutters>
        <template v-for="(item, index) in editedItem" >
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="!item.hide" :key="item.name">
                <!-- item type === text -->
                <v-text-field v-if="!item.options && (item.type === 'VARCHAR' || item.type === 'TEXT')"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <!-- item columnType === check -->
                <v-switch v-if="!item.options && item.type === 'INT' && item.columnType === 'check'"
                    :label="item.label"
                    :value="formatSwitchValue(item)"
                    @change="setSwitchValue($event, item)"
                />

                <v-btn v-if="item.name === 'main'" @click="$root.$emit('openAddress')" class="primary--text pa-0 mt-n4" text>
                    + Criar novo Endereço
                </v-btn>

                <!-- item type === int -->
                <v-text-field v-if="!item.options && item.type === 'INT' && item.columnType !== 'check'"
                    v-model.number="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="number"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <!-- item type === decimal -->
                <v-text-field v-if="!item.options && item.type === 'DECIMAL'"
                    v-model.number="item.value"
                    @change="formatFloat(item.value)"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="number"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <v-autocomplete v-if="item.options && item.name !== 'customer_type'"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                />
                
                <!-- item type === date or item type === datetime -->
                <v-menu v-if="item.type === 'DATE' || item.type === 'DATETIME'"
                    :ref="'menu' + index"
                    v-model="datepicker[index]"
                    :close-on-content-click="false"
                    :return-value.sync="item.value"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="formatDate(item.value)"
                            :label="isRequired(item) ? item.label + ' *' : item.label"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                            :clearable="!isRequired(item)"
                            @click:clear="clearDatepicker(item.name, $event)"
                        ></v-text-field>
                    </template>

                    <v-date-picker
                        v-model="item.value"
                        no-title
                        scrollable
                        color="primary"
                    >
                        <v-spacer></v-spacer>

                        <!-- <v-btn text color="primary" @click="datepicker[index] = false">
                            Cancelar
                        </v-btn> -->
                        
                        <v-btn text color="primary" @click="saveSelectedDate(item.value, index)">
                            Alterar
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    name: 'ZnapDialog',

    props: {
        editedItem: { type: Array, required: true },
        editedIndex: { type: Number, required: true },
        disabled: { type: Boolean, required: true },
        showVendor: { type: Boolean, default: true },
        options: { type: Array, required: true }
    },

    data() {
        return {
            datepicker: [],
            date: '',
            selected: [],
            show: false,
            allowShow: false,
            // Para salvar o valor do tipo
            valueType: ''
        }
    },

    methods: {
        getOptions(item) {
            let option = this.options.find(option => option.column === item.columnId)
            return option.items
        },
        
        setSelectedOption(columnId, e) {
            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === columnId) this.editedItem[i].value = e.id
                } else {
                    if (item.name === columnId) this.editedItem[i].value = null
                }
            })
        },

        clearDatepicker(itemName, e) {
            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === itemName) this.editedItem[i].value = e.id
                } else {
                    if (item.name === itemName) this.editedItem[i].value = null
                }
            })
        },

        requiredValidation(item) {
            if (item && item.value !== null) {
                if ( item.value.length === 0 ) return true
            }
        },

        isRequired(item) {
            return item.rules.includes('required') ? true : false 
        },

        saveSelectedDate(date, index) {
            this.$refs['menu' + index][0].save(date)
            this.date = this.$options.filters.dateFilter(date)
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        formatFloat(float) {
            if (float === undefined || float === null) {
                return ''
            } else {
                return this.$options.filters.floatFilter(float)
            }
        },

        formatSwitchValue(item) {
            let value = null

            if (item.value === 1) {
                value = true
                item.value = 1
            }

            if (item.value === null || item.value === 0) {
                value = false
                item.value = 0
            }

            return value
        },

        setSwitchValue(e, item) {
            if (e) {
                item.value = 1
            } else {
                item.value = 0
            }
        }
    },
}
</script>

<style>

</style>