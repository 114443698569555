<template>
    <v-row no-gutters>
        <template v-for="(item, index) in editedItem">
            <v-col
                data-testid="znap-dialog-input"
                v-if="!item.hide" :key="item.name"
                cols="12" sm="12" md="12" lg="12" xl="12"
            >
                <!-- item type === text -->
                <v-text-field v-if="!item.options && (item.type === 'VARCHAR' || item.type === 'TEXT')"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- item columnType === check -->
                <!-- before refactor:
                    :value="formatSwitchValue(item)"
                    @change="setSwitchValue($event, item)" -->
                <v-switch v-if="!item.options && item.type === 'INT' && item.columnType === 'check'"
                    class="mt-0"
                    :label="item.label"
                    v-model="item.value"
                    @change="setSwitchValue($event, item)"
                />

                <!-- <v-btn v-if="item.name === 'main'" @click="$root.$emit('openAddress')" class="primary--text pa-0 mt-n4" text>
                    + Criar novo Endereço
                </v-btn> -->

                <!-- item type === int -->
                <v-text-field v-if="!item.options && item.type === 'INT' && item.columnType !== 'check'"
                    v-model.number="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="number"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- item type === decimal -->
                <v-text-field v-if="!item.options && item.type === 'DECIMAL'"
                    v-model.number="item.value"
                    @change="formatFloat(item.value)"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="number"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- country code options -->
                <v-autocomplete v-if="item.options && item.name.includes('country_code') && !show"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="item.options"
                    item-value="text"
                    :item-text="item => `${item.text} (${item.code})`"
                    return-object
                    @change="setSelectedOption(null, $event, item)"
                    :clearable="true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- item has options with items already populated -->
                <v-autocomplete v-if="item.options && item.options.length > 0 && !item.name.includes('country_code') && !show && item.name !== 'vendor_description' && item.name !== 'customer_param' && item.name !== 'product_param'"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="item.options"
                    item-text="text"
                    item-value="id"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- item has options -->
                <v-autocomplete v-if="item.options && !show && item.name !== 'vendor_description' && item.name !== 'customer_param' && item.name !== 'product_param' && !item.options.length && !item.name.includes('country_code')"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event, item)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- vendor description options -->
                <v-autocomplete v-if="item.options && showVendor && item.name === 'vendor_description'"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event, item)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- customer param options -->
                <v-autocomplete v-if="item.options && !allowShow && item.name === 'customer_param'"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event, item)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <!-- product param options -->
                <v-autocomplete v-if="item.options && !allowShow && item.name === 'product_param'"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event, item)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :readonly="item.readOnly ? item.readOnly : false"
                />
                
                <!-- Salvar novo parâmetro -->
                <v-text-field v-if="item.name === 'customer_param' && allowShow"
                    v-model="item.value"
                    :maxlength="item.length"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    label="Nome do parâmetro do cliente *"
                    type="text"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <v-text-field v-if="item.name === 'customer_param' && allowShow"
                    v-model="valueType"
                    maxlength="1"
                    @input="$root.$emit('valueType', valueType)"
                    label="Tipo do valor *"
                    type="text"
                    :readonly="item.readOnly ? item.readOnly : false"
                />

                <v-btn v-if="item.name === 'customer_param'" @click="allowShow = !allowShow, item.value = null, $root.$emit('saveType', allowShow)" class="primary--text pa-0 mt-n4" text>
                    <span v-if='!allowShow'>
                        + Criar novo parâmetro
                    </span>
                    <span v-if='allowShow'>
                        Escolher um parâmetro já salvo
                    </span>
                </v-btn>

                <!-- Salvar novo parâmetro de produto -->
                <v-text-field v-if="item.name === 'product_param' && allowShow"
                    v-model="item.value"
                    label="Nome do parâmetro do produto *"
                    type="text"
                />

                <v-text-field v-if="item.name === 'product_param' && allowShow"
                    v-model="valueType"
                    maxlength="1"
                    @input="$root.$emit('valueType', valueType)"
                    label="Tipo do valor *"
                    type="text"
                />

                <v-btn v-if="item.name === 'product_param'" @click="allowShow = !allowShow, item.value = null, $root.$emit('saveType', allowShow)" class="primary--text pa-0 mt-n4" text>
                    <span v-if='!allowShow'>
                        + Criar novo parâmetro
                    </span>
                    <span v-if='allowShow'>
                        Escolher um parâmetro já salvo
                    </span>
                </v-btn>
                
                <!-- item type === date or item type === datetime -->
                <v-menu v-if="item.type === 'DATE' || item.type === 'DATETIME'"
                    :ref="'menu' + index"
                    v-model="datepicker[index]"
                    :close-on-content-click="false"
                    :return-value.sync="item.value"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="formatDate(item.value)"
                            :label="isRequired(item) ? item.label + ' *' : item.label"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                            :clearable="!isRequired(item)"
                            @click:clear="clearDatepicker(item.name, $event)"
                        ></v-text-field>
                    </template>

                    <v-date-picker
                        v-model="item.value"
                        no-title
                        scrollable
                        color="primary"
                    >
                        <v-spacer></v-spacer>
                        
                        <v-btn text color="primary" @click="saveSelectedDate(item.value, index)">
                            Alterar
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    name: 'ZnapDialog',

    props: {
        editedItem: { type: Array, required: true },
        editedIndex: { type: Number, required: true },
        disabled: { type: Boolean, required: true },
        showVendor: { type: Boolean, default: true },
        options: { type: Array, required: true }
    },

    data() {
        return {
            datepicker: [],
            date: '',
            selected: [],
            show: false,
            allowShow: false,
            // Para salvar o valor do tipo
            valueType: ''
        }
    },

    watch: {
        editedIndex: {
            immediate: true,
            handler(val) {
                if (val > -1) {
                    this.options.forEach(o => {
                        if (o.filters) {
                            let item = this.editedItem.find(i => i.columnId === o.column)
                            if (item) {
                                let selection = o.items.find(i => i.text === item.value)
                                if (selection) this.populateFilterableFilter(o, selection)
                            }
                        }
                    })
                }
            }
        }
    },

    created () {
        this.$root.$on('clearType', () => {
            this.valueType = ''
        })
    },

    methods: {
        getOptions(item) {
            let option = this.options.find(option => option.column === item.columnId)
            let items = option.items

            if (option.filteredBy) {
                items = option.filteredItems
            }

            return items
        },

        verifyOptionItemsLength(item) {
            let items = this.getOptions(item)
            return items.length ? false : true 
        },

        populateFilterableFilter(option, selection) {
            option.filters.forEach(async f => {
                let foundOption = this.options.find(o => o.column === f)

                let filter = {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: option.column,
                            operator: '=',
                            value: selection.id
                        }
                    ]
                }

                try {
                    let res = await this.$http.post(foundOption.endpoint[0] + foundOption.endpoint[1] + '/list-options', { filter })
                    if (res) {
                        foundOption.filteredItems = res.data.rows
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            })
        },
        
        setSelectedOption(columnId, e, item) {
            // verify if selected option filters another input field
            let found = this.options.find(o => o.column === columnId)
            if (found) {
                if (found.filters) {
                    found.filters.forEach(f => {
                        let foundOption = this.options.find(o => o.column === f)
                        let foundEditedItemColumn = this.editedItem.find(e => e.columnId === foundOption.column)
                        foundEditedItemColumn.value = null

                        this.populateFilterableFilter(found, e)
                    })
                }
            }

            if (item.name.includes('country_code')) {
                let index = this.editedItem.findIndex(i => i.name === item.name)
                let value = null

                if (e) {
                    value = e.text.slice(0, 2)
                }

                this.editedItem[index].value = value
            } else {
                this.editedItem.forEach((item, i) => {
                    if (e) {
                        if (item.name === columnId) this.editedItem[i].value = e.id
                    } else {
                        if (item.name === columnId) this.editedItem[i].value = null
                    }
                })
            }
        },

        clearDatepicker(itemName, e) {
            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === itemName) this.editedItem[i].value = e.id
                } else {
                    if (item.name === itemName) this.editedItem[i].value = null
                }
            })
        },

        requiredValidation(item) {
            if (item && item.value !== null) {
                if ( item.value.length === 0 ) return true
            }
        },

        isRequired(item) {
            return item.rules.includes('required') ? true : false 
        },

        saveSelectedDate(date, index) {
            this.$refs['menu' + index][0].save(date)
            this.date = this.$options.filters.dateFilter(date)
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        formatFloat(float) {
            if (float === undefined || float === null) {
                return ''
            } else {
                return this.$options.filters.floatFilter(float)
            }
        },

        setSwitchValue(e, item) {
            if (e) {
                item.value = 1
            } else {
                item.value = 0
            }
        }
    }
}
</script>

<style>

</style>